<template>
  <a-layout class="custom-layout">
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="content-title auto">
          <div class="coustom-title fl">
            <a-button icon="double-left" size="small" class="m-r-10 fl" @click="onClose"> 返回 </a-button>
            <span class="fl">二级授权（{{count}}人）</span>
          </div>
          <div class="coustom-title-mes fr">
            <a-icon type="info-circle" theme="filled" style="color:#1890FF;margin-right: 10px;" />已授权人员 {{count}} 人
          </div>
        </div>
        <div class="content-bar auto">
          <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addUser"> 添加管理员 </a-button>
              <a-input type="text" v-model="filter.param" size="small" placeholder="请输入学工号/姓名" class="m-r-5 fl" style="width:160px;" allowClear></a-input>
              <a-button type="primary" size="small" class="fl" @click="searchHandle">查找</a-button>
            </div>
          </custom-page>
        </div>
        <div class="content-table auto">
          <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
            <div slot="department" slot-scope="text">
              <span v-for="(item, index) in text" :key="index">{{departmentName[item]}}<span v-show="index !== text.length - 1">，</span></span>
            </div>
            <div slot="roles" slot-scope="text">
              <span v-for="(item1, index) in text" :key="index">{{filters['roles'] && filters['roles'].find((item) => { return item1 == item.value}) && filters['roles'].find((item) => { return item1 == item.value})['label']}}<span v-show="index !== text.length - 1">，</span></span>
            </div>
            <div slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" class="m-r-5" @click="editRoles(record)">编辑</a-button>
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="deleteUser(record)"
              >
                <a-button type="primary" size="small" class="bgred borred">删除</a-button>
              </a-popconfirm>
            </div>
          </a-table>
        </div>
        <div class="content-bar auto">
          <custom-page :total="count" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addUser"> 添加管理员 </a-button>
              <a-input type="text" v-model="filter.param" size="small" placeholder="请输入学工号/姓名" class="m-r-5 fl" style="width:160px;" allowClear></a-input>
              <a-button type="primary" size="small" class="fl" @click="searchHandle">查找</a-button>
            </div>
          </custom-page>
        </div>
      </a-spin>
      <a-drawer
        width="100%"
        title=""
        placement="right"
        :closable="false"
        :visible="visible"
        :get-container="false"
        :wrap-style="{ position: 'absolute' }"
      >
        <div class="content-title auto" style="margin-top: -15px;">
          <div class="coustom-title fl">
            <a-button icon="double-left" size="small" class="m-r-10 fl" @click="onBack"> 返回 </a-button>
            <span class="fl">添加管理员</span>
          </div>
          <div class="coustom-title-mes fr">
            <a-icon type="info-circle" theme="filled" style="color:#1890FF;margin-right: 10px;" />为您检索到 {{total2}} 人
          </div>
        </div>
        <div class="content-bar auto">
          <custom-page :total="total2" @getPageMes="getPageMes2" :page="filter2.current_page" :limit="filter2.page_size">
            <div slot="custom">
              <a-input type="text" v-model="filter2.search" size="small" placeholder="请输入学工号/姓名" class="m-r-5 fl" allowClear style="width:160px;"></a-input>
              <a-button type="primary" size="small" class="fl" @click="searchHandle2">查找</a-button>
            </div>
          </custom-page>
        </div>
        <div class="content-table auto">
          <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns2" :data-source="tabledata2" size="middle" :pagination="false">
            <div slot="department" slot-scope="text">
              <span v-for="(item, index) in text" :key="index">{{departmentName[item]}}<span v-show="index !== text.length - 1">，</span></span>
            </div>
            <div slot="roles" slot-scope="text">
              <span v-for="(item1, index) in text" :key="index">{{filters['roles'] && filters['roles'].find((item) => { return item1 == item.value}) && filters['roles'].find((item) => { return item1 == item.value})['label']}}<span v-show="index !== text.length - 1">，</span></span>
            </div>
            <div slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" class="m-r-5 bgyellow boryellow" @click="addRoles(record)" v-if="!record.roles.length">添加</a-button>
              <a-button type="primary" size="small" class="m-r-5" @click="editRoles(record)" v-if="record.roles.length">编辑</a-button>
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="deleteUser(record)"
              >
                <a-button type="primary" size="small" class="bgred borred" v-if="record.roles.length">删除</a-button>
              </a-popconfirm>
            </div>
          </a-table>
        </div>
      </a-drawer>
    </a-layout-content>
    <a-modal v-model="addVisible" title="角色操作" @cancel="addCancel" @ok="addSubmit" width="450px">
      <a-row>
        <a-col :span="24">
          {{detail_row.schoolid}}，{{detail_row.name}}，<span v-for="(item, index) in detail_row.department" :key="index">{{departmentName[item]}}<span v-show="index !== detail_row.department.length - 1">，</span></span>
        </a-col>
      </a-row>
      <a-row class="m-t-10">
        <a-col :span="24">
          <div class="current_roles_box">
            <a-checkbox-group
              v-model="current_roles"
            >
              <a-row>
                <a-col :span="12" v-for="(item, index) in rolesList" :key="index">
                  <a-checkbox :value="item.value" :disabled="item.disabled">
                    <span class="ellipsis roles_item" :title="item.label">{{item.label}}</span>
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      isAdd: false,
      loading: true,
      filters: {},
      departmentName: {},
      departmentNameDone: false,
      filter: {
        search: '',
        page_size: 10,
        current_page: 1
      },
      count: 0,
      msg: {},
      tablecolumns: [
        {
          title: '学工号',
          dataIndex: 'schoolid'
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '授权角色',
          dataIndex: 'roles',
          scopedSlots: { customRender: 'roles' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          customCell: () => {
            return {
              style: {
                'width': '150px',
              }
            }
          }
        }
      ],
      tabledata: [],
      visible: false,
      tablecolumns2: [
        {
          title: '学工号',
          dataIndex: 'schoolid'
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '授权角色',
          dataIndex: 'roles',
          scopedSlots: { customRender: 'roles' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          customCell: () => {
            return {
              style: {
                'width': '150px',
              }
            }
          }
        }
      ],
      tabledata2: [],
      filter2: {
        search: '',
        page_size: 10,
        current_page: 1
      },
      total2: 0,
      addVisible: false,
      current_roles: [],
      detail_row: {},
      rolesList: []
    }
  },
  methods: {
    onBack () {
      this.visible = false
    },
    async addSubmit () {
      let list = []
      this.current_roles.map(item => {
        let status = this.detail_row.roles.find(item2 => {
          return Number(item2.roleid) === Number(item)
        })
        if (!status) {
          list.push(item)
        }
      })
      let res = null
      if (this.isAdd) {
        res = await System.addDecentralizationUser(this.detail_row.userid, list)
      } else {
        res = await System.changeDecentralizationUser(this.detail_row.userid, list)
      }
      if (!res['code']) {
        this.$message.success('操作成功！')
        this.addCancel()
        this.getDecentralizationUser()
        this.getDecentralizationUserAll()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    addCancel () {
      this.detail_row = {}
      this.current_roles = []
      this.addVisible = false
    },
    async deleteUser (row) {
      let res = await System.deleteDecentralizationUser(row.userid)
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getDecentralizationUser()
        this.getDecentralizationUserAll()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    editRoles (row) {
      this.isAdd = false
      this.detail_row = row
      this.current_roles = row.roles
      this.addVisible = true
      this.rolesList = JSON.parse(JSON.stringify(this.filters['roles']))
    },
    addRoles (row) {
      this.isAdd = true
      this.detail_row = row
      this.current_roles = row.roles
      this.addVisible = true
      this.rolesList = JSON.parse(JSON.stringify(this.filters['roles']))
    },
    onClose () {
      this.$router.go(-1)
    },
    getPageMes (mes) {
      this.filter.current_page = mes.pageNumber
      this.filter.page_size = mes.pageSize
    },
    addUser () {
      this.visible = true
      if (!this.tabledata2.length) {
        this.getDecentralizationUserAll()
      }
    },
    searchHandle2 () {
      this.filter2.current_page = 1
      this.getDecentralizationUserAll()
    },
    getPageMes2 (obj) {
      this.filter2.current_page = obj.pageNumber
      this.filter2.page_size = obj.pageSize
      this.getDecentralizationUserAll()
    },
    async getDecentralizationUserAll () {
      this.loading = true
      let res = await System.getDecentralizationUser({
        type: 'all',
        param: this.filter2.search,
        current_page: this.filter2.current_page,
        page_size: this.filter2.page_size
      })
      this.loading = false
      if (!res['code']) {
        this.tabledata2 = res['data']
        this.total2 = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    searchHandle () {
      this.filter.current_page = 1
      this.getDecentralizationUser()
    },
    async getDecentralizationUser () {
      this.loading = true
      let res = await System.getDecentralizationUser({
        type: 'own',
        param: this.filter.search,
        current_page: this.filter.current_page,
        page_size: this.filter.page_size
      })
      this.loading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.count = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    trunDepartmentData (data) {
      for (let k in data) {
        this.departmentName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunDepartmentData(data[k]['children'])
        }
      }
    },
    async getDecentralizationUserRole () {
      let res = await System.getDecentralizationUserRole()
      if (!res['code']) {
        let rolesList = []
        res['data'].map(item => {
          rolesList.push({
            value: String(item.id),
            label: item.name
          })
        })
        this.filters['roles'] = rolesList
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
      let res2 = await System.getDecentralizationDepartment()
      if (!res2['code']) {
        this.filters['department'] = res2['data']
        this.trunDepartmentData(res2['data'])
        this.departmentNameDone = true
      } else {
        // this.$message.error(res2['description'])
        this.$message.error(this.errMsg[res2['code']])
      }
      this.getDecentralizationUser()
    }
  },
  created () {
    this.getDecentralizationUserRole()
  }
};
</script>

<style scoped>
.content-bar {
  padding: 8px 0;
}
.log-page {
  padding: 20px 0;
}

.roles_item {
  display: inline-block;
  width: 140px;
  /*height: 20px;*/
  line-height: 30px;
  margin-bottom: -10px;
  overflow: hidden;
}

.current_roles_box {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}
</style>
